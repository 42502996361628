<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="TruckIcon"
        size="25"
      />Veicoli {{ headquarter_name }}</h1>
    </b-card>
    <!-- Filters -->
    <b-card
      no-body
      class="mb-0"
    >
      <vehicles-list-filters
        :type-filter.sync="typeFilter"
        :availability-filter.sync="availabilityFilter"
        :type-options="typeOptions"
        :availability-options="availabilityOptions"
      />

      <!-- Table Container Card -->

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
              <!-- <b-button variant="primary" class="text-nowrap" :to="{ name: 'apps-vehiclesreception-add' }"
                >Nuovo Veicolo</b-button
              > -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refVehiclesListTable"
        class="position-relative"
        :items="fetchVehicles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Veicolo corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-link :to="{ name: 'apps-vehiclesreception-view', params: { id: data.item.id } }">
                <b-img
                  :src="data.item.image_path ? mediaUrl + data.item.image_path : ''"
                  v-bind="data.item.image_path ? mainProps : blankProps"
                  rounded
                  alt="Immagine Veicolo"
                  class="vehicle_list_img d-inline-block"
                />
              </b-link>
            </template>
            <b-link
              :to="{ name: 'apps-vehiclesreception-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
            <small class="text-muted">{{ data.item.factory_year }}</small>
          </b-media>
        </template>

        <!-- Column: Type -->
        <template #cell(tipo)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
          </div>
        </template>

        <!-- Column: Model -->
        <template #cell(modello)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.model }}</span>
          </div>
        </template>

        <!-- Column: Availability -->
        <template #cell(disponibilità)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              <feather-icon
                icon="CircleIcon"
                size="15"
                :class="[vehicleAvailabilitiesColor[data.item.availability_status], 'mr-1']"
              />
              {{ vehicleAvailabilities[data.item.availability_status] }}
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-vehiclesreception-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-vehiclesreception-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifica</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              @click="
                $bvModal.show('delete-modal')
                selectedVehicle = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancella</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalVehicles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="delete-modal"
      title="Cancella Veicolo"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Veicolo
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questo Veicolo e i suoi dati correlati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteVehicle(selectedVehicle)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BMedia,
  BLink,
  BImg,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVehiclesList from './useVehiclesList'
import vehiclesStoreModule from '../vehiclesStoreModule'
import VehiclesListFilters from './VehiclesListFilters.vue'

export default {
  components: {
    VehiclesListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BLink,
    BImg,
    BModal,

    vSelect,
  },
  setup() {
    const VEHICLES_APP_STORE_MODULE_NAME = 'app-vehicles'

    // Register module
    if (!store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) {
      store.registerModule(VEHICLES_APP_STORE_MODULE_NAME, vehiclesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(VEHICLES_APP_STORE_MODULE_NAME)
      }
    })

    const typeOptions = [
      { label: 'Auto', value: 'Auto' },
      { label: 'Furgone', value: 'Furgone' },
      { label: 'Camion', value: 'Camion' },
    ]

    const availabilityOptions = [
      { label: 'Disponibile', value: 'available' },
      { label: 'Non Disponibile', value: 'unavailable' },
      { label: 'Occupato', value: 'BUSY' },
    ]

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const headquarter_name = localStorage.getItem('headquarter_name')

    const {
      fetchVehicles,
      tableColumns,
      perPage,
      currentPage,
      totalVehicles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVehiclesListTable,
      refetchData,

      // Extra Filters
      typeFilter,
      availabilityFilter,
    } = useVehiclesList()

    return {
      fetchVehicles,
      tableColumns,
      perPage,
      currentPage,
      totalVehicles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVehiclesListTable,
      refetchData,

      typeOptions,
      availabilityOptions,

      // Extra Filters
      typeFilter,
      availabilityFilter,

      // Filter
      avatarText,
      vehicleAvailabilitiesColor: {
        available: 'text-success',
        unavailable: 'text-secondary',
        BUSY: 'text-warning',
      },
      vehicleAvailabilities: {
        available: 'Disponibile',
        unavailable: 'Non Disponibile',
        BUSY: 'Occupato',
      },
      mainProps: {
        width: 100,
        height: 50,
      },
      blankProps: {
        blank: true,
        blankColor: '#777',
        width: 100,
        height: 50,
      },
      selectedVehicle: null,
      mediaUrl,
      headquarter_name,
    }
  },
  methods: {
    deleteVehicle(id) {
      store
        .dispatch('app-vehicles/deleteVehicle', { id })
        .then(() => {
          this.selectedVehicle = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Veicolo #${id} eliminato con successo`,
              icon: 'TruckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vehicle_list_img {
  object-fit: cover;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
