import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useVehiclesList() {
  // Use toast
  const toast = useToast()

  const refVehiclesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'tipo', sortable: true },
    { key: 'modello', sortable: true },
    { key: 'disponibilità', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    nome: 'display_name',
    tipo: 'type',
    modello: 'model',
    disponibilità: 'availability_status',
  }

  const headquarter_id = localStorage.getItem('headquarter_id')

  const perPage = ref(10)
  const totalVehicles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const typeFilter = ref(null)
  const availabilityFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refVehiclesListTable.value ? refVehiclesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVehicles.value,
    }
  })

  const refetchData = () => {
    refVehiclesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, typeFilter, availabilityFilter], () => {
    refetchData()
  })

  const fetchVehicles = (ctx, callback) => {
    store
      .dispatch('app-vehicles/fetchVehicles', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        type: typeFilter.value,
        availability_status: availabilityFilter.value,
        headquarter_id
      })
      .then((response) => {
        const vehicles = response.data[0]
        const total = response.data[1]

        callback(vehicles)
        totalVehicles.value = total
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchVehicles,
    tableColumns,
    perPage,
    currentPage,
    totalVehicles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVehiclesListTable,

    refetchData,

    // Extra Filters
    typeFilter,
    availabilityFilter,
  }
}
